import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './Organizations.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  changeSortDirection,
  Col,
  offsets,
  openStatusNotification,
  Row,
  Search,
  SortDirection,
  Table,
  TableAction,
  TableColumn,
  TableRow,
  Typography
} from '@xq/ui-kit';
import { ManagerList } from './dataTypes';
import {
  OrganizationsService,
  OrganizationsServiceApi
} from './organizations-service';
import { useDebounce } from '@hooks';
import { config } from '@config';
import { SORT_DIRECTIONS } from '@constants';
import { debounce, getStatusNotificationTranslations } from '@services';
import { ListMetaInfo, ListParams } from 'interfaces';
import {
  createOrganizationsColumns,
  createOrganizationsRows
} from '@pages/Organizations/Organizations/utils';
import type { OrganizationListItemDTOResponse } from '@xq/omni-gateway-frontend-client';

export const Organizations: FC = () => {
  const service: OrganizationsService = new OrganizationsServiceApi();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const columns: TableColumn[] = createOrganizationsColumns(t);

  const [organizations, setOrganizations] = useState<
    OrganizationListItemDTOResponse[]
  >([]);
  const [organizationsMeta, setOrganizationsMeta] =
    useState<ListMetaInfo>(null);
  const [managers, setManagers] = useState<ManagerList[]>([]);
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);

  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.ASC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('name');
  const [noMoreData, setNoMoreData] = useState<boolean>(false);

  const actions: TableAction[] = [
    {
      key: 'redirect',
      title: 'Redirect',
      icon: 'chevrons-right',
      onClick: redirectToOrganization
    }
  ];

  function redirectToOrganization(row: TableRow) {
    navigate(row.id);
  }

  async function changeManager(rowId: string, newManagerValue: string) {
    const targetIndex = organizations.findIndex((el) => el.uuid === rowId);
    const newData = [...organizations];
    newData[targetIndex].managerUuid = newManagerValue;

    setOrganizations(newData);

    try {
      await service.setOrganizationManager(
        newData[targetIndex].uuid,
        newManagerValue
      );
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const getQueryParams = (optionalParams?: ListParams): ListParams => {
    const params: ListParams = optionalParams || {};

    if (!params.searchValue && debouncedSearchValue) {
      params.searchValue = String(debouncedSearchValue);
    }

    if (!params.sortOrder && sortOrder) {
      params.sortOrder = sortOrder;
    }

    if (!params.sortBy && sortBy) {
      params.sortBy = mapColumnKey(sortBy);
    }

    return params;
  };

  async function fetchOrganizations(optionalParams?: ListParams) {
    const params = getQueryParams(optionalParams);
    setIsLoading(true);
    try {
      const res = await service.fetchOrganizations(params);
      const hasResults = !res.items || res?.items?.length === 0;
      setNoResults(hasResults);
      setOrganizations(res?.items);
      setOrganizationsMeta(res?.meta);
      checkNoMoreData(res?.items?.length, res?.meta?.totalItems);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
    return { updatedSortBy: updatedSortBy, updatedSortOrder: updatedSortOrder };
  }

  async function fetchManagers() {
    try {
      const managers = await service.fetchManagers();
      setManagers(managers);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const rows = useMemo(() => {
    return createOrganizationsRows(organizations, managers, changeManager, t);
  }, [organizations, managers]);

  const mapColumnKey = (key: string): string => {
    switch (key) {
      case 'lastBillingDate': {
        return 'licenses';
      }
      default: {
        return key;
      }
    }
  };

  const checkNoMoreData = (organizationLength: number, totalItems: number) => {
    const isNoMoreData =
      organizationLength === totalItems || organizationLength === 0;
    setNoMoreData(isNoMoreData);
  };

  async function loadMoreOrganizations() {
    try {
      if (noMoreData) {
        return;
      }

      const params: ListParams = getQueryParams();

      if (organizationsMeta?.currentPage) {
        params.page = ++organizationsMeta.currentPage;
      }

      const response = await service.fetchOrganizations(params);
      const { items } = response;
      checkNoMoreData(items?.length, response?.meta?.totalItems);
      setOrganizations([...organizations, ...items]);
      setOrganizationsMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    fetchManagers();
  }, []);

  const updateSearchParams = useCallback(
    debounce((newValue) => {
      if (newValue) {
        searchParams.set('search', newValue);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, config.debounceDelay),
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    const searchParamValue = searchParams.get('search') || '';
    setSearchValue(searchParamValue);
  }, [searchParams]);

  const handleSearchValue = (newValue: string) => {
    setSearchValue(newValue);
    updateSearchParams(newValue);
  };

  useEffect(() => {
    fetchOrganizations({
      searchValue: String(debouncedSearchValue),
      sortOrder: sortOrder,
      sortBy: mapColumnKey(sortBy)
    });
  }, [sortOrder, sortBy, debouncedSearchValue]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10} className={styles.heading}>
          <Typography element="div" variant="h2">
            {t('organizations.organizations')}
          </Typography>
          <div>
            <Button
              type="third"
              icon="briefcase"
              onClick={() => navigate('create')}
            >
              {t('common.create')}
            </Button>
          </div>
        </Col>

        <Col col={6} lg={3} className={offsets['mb-20']}>
          <Search
            placeholder={t('common.searchByName')}
            value={searchValue}
            onChange={handleSearchValue}
          />
        </Col>

        <Col col={10}>
          <Table
            rows={rows}
            columns={columns}
            actions={actions}
            maxHeight="640px"
            minWidth={1100}
            noMoreData={noMoreData}
            noMoreItemsText={t('common.noMoreItems')}
            onLoading={loadMoreOrganizations}
            onSort={updateSortParams}
            sortOrder={sortOrder}
            sortBy={sortBy}
            noResults={noResults}
            noResultsText={t('common.noResults')}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

Organizations.displayName = 'Organizations';
