import { handleBackendError, omniApiConfiguration } from '@services';
import { ListParams } from 'interfaces';

import {
  ApiKeyInfoListSortOptionsDTOFieldNameEnum,
  ApiKeyInfoListSortOptionsDTOOrderEnum,
  ApiKeyListDTOResponse,
  ApiKeyListPageApi
} from '@xq/omni-gateway-frontend-client';

export interface ApiKeysService {
  fetchApiKeys(
    organizationUuid: string,
    params?: ListParams
  ): Promise<ApiKeyListDTOResponse>;

  deleteApiKey(organizationUuid: string, apiKeyUuid: string): Promise<void>;
}

const apiKeysListServiceGateway = new ApiKeyListPageApi(omniApiConfiguration);

export class ApiKeysServiceApi implements ApiKeysService {
  async fetchApiKeys(
    organizationUuid: string,
    params?: ListParams
  ): Promise<ApiKeyListDTOResponse> {
    try {
      return await apiKeysListServiceGateway.apiKeyListPageControllerGetApiKeysForOrganization(
        {
          organizationUuid: organizationUuid,
          searchString: params?.searchValue?.trim(),
          apiKeyInfoListOptionsDTORequest: {
            pagination: {
              page: params?.page,
              limit: params?.limit
            },
            filters: [],
            sort: [
              {
                order:
                  params?.sortOrder as ApiKeyInfoListSortOptionsDTOOrderEnum,
                fieldName:
                  params?.sortBy as ApiKeyInfoListSortOptionsDTOFieldNameEnum
              }
            ]
          }
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchApiKeys.name
      );
    }
  }

  async deleteApiKey(
    organizationUuid: string,
    apiKeyUuid: string
  ): Promise<void> {
    try {
      return await apiKeysListServiceGateway.apiKeyListPageControllerDeleteApiKeyByUUID(
        {
          apiKeyUuid,
          organizationUuid
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteApiKey.name
      );
    }
  }
}
