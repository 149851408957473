import {
  handleBackendError,
  mapFiltersFromGatewayToUI,
  omniApiConfiguration,
  wait
} from '@services';
import { User, UsersData, UsersPageData } from '@pages';
import { ListMetaInfo, ListParams, TableFilterValues } from 'interfaces';
import {
  GatewayListFiltersDTOResponse,
  UserListSortOptionsDTOFieldNameEnum,
  UserListSortOptionsDTOOrderEnum,
  UsersListPageApi
} from '@xq/omni-gateway-frontend-client';
import { TableFilter, TableFilterGroup } from '@xq/ui-kit';

export interface UsersService {
  fetchData(organizationUuid: string): Promise<UsersPageData>;

  fetchFilters(organizationUuid: string): Promise<TableFilterValues>;

  fetchUsers(organizationUuid: string, params?: ListParams): Promise<UsersData>;

  deleteUser(organizationUuid: string, userUuid: string): Promise<void>;

  blockUser(organizationUuid: string, userUuid: string): Promise<void>;

  unblockUser(organizationUuid: string, userUuid: string): Promise<void>;

  resetPassword(
    organizationUuid: string,
    userUuid: string,
    password: string,
    passwordChangeRequired?: boolean
  ): Promise<void>;

  exportAllUsers(organizationUuid: string): Promise<string>;
}

const omniUsersGateway = new UsersListPageApi(omniApiConfiguration);

export class UsersServiceApi implements UsersService {
  async fetchUsers(
    organizationUuid: string,
    params?: ListParams
  ): Promise<UsersData> {
    try {
      return await omniUsersGateway.userListPageControllerGetUserList({
        organizationUuid: organizationUuid,
        searchString: params?.searchValue?.trim(),
        userListOptionsDTORequest: {
          pagination: {
            page: params?.page,
            limit: params?.limit
          },
          filters: params?.filters,
          sort: [
            {
              order: params?.sortOrder as UserListSortOptionsDTOOrderEnum,
              fieldName: params?.sortBy as UserListSortOptionsDTOFieldNameEnum
            }
          ]
        }
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchUsers.name
      );
    }
  }

  async fetchFilters(organizationUuid: string): Promise<TableFilterValues> {
    try {
      const filters: GatewayListFiltersDTOResponse =
        await omniUsersGateway.userListPageControllerGetUserListFilters({
          organizationUuid
        });

      const mappedFilters: TableFilter[] = mapFiltersFromGatewayToUI(
        filters.filters
      );

      const mappedGroups: TableFilterGroup[] = filters.groups?.map((group) => {
        return {
          id: group.groupId,
          groupTitle: group.groupTitle
        };
      });

      return {
        filters: mappedFilters,
        groups: mappedGroups
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchFilters.name
      );
    }
  }

  async fetchData(organizationUuid: string): Promise<UsersPageData> {
    try {
      return await omniUsersGateway.userListPageControllerGetPageData({
        organizationUuid: organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async deleteUser(organizationUuid: string, userUuid: string): Promise<void> {
    try {
      return await omniUsersGateway.userListPageControllerPostDeleteUser({
        userUuid: userUuid,
        organizationUuid: organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteUser.name
      );
    }
  }

  async blockUser(organizationUuid: string, userUuid: string): Promise<void> {
    try {
      return await omniUsersGateway.userListPageControllerPostBlockUser({
        userUuid: userUuid,
        organizationUuid: organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.blockUser.name
      );
    }
  }

  async unblockUser(organizationUuid: string, userUuid: string): Promise<void> {
    try {
      return await omniUsersGateway.userListPageControllerPostUnblocklockUser({
        userUuid: userUuid,
        organizationUuid: organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.unblockUser.name
      );
    }
  }

  async resetPassword(
    organizationUuid: string,
    userUuid: string,
    password: string,
    passwordChangeRequired?: boolean
  ): Promise<void> {
    try {
      await omniUsersGateway.userListPageControllerPostSetPasswordUser({
        userUuid: userUuid,
        organizationUuid: organizationUuid,
        userSetPasswordDTORequest: {
          password,
          passwordChangeRequired
        }
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.resetPassword.name
      );
    }
  }

  async exportAllUsers(organizationUuid: string): Promise<string> {
    try {
      return await omniUsersGateway.userListPageControllerGetActivityLogFile({
        organizationUuid: organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.exportAllUsers.name
      );
    }
  }
}

const usersMock: User[] = [
  {
    uuid: '1',
    avatarUrl: '',
    fullname: 'Charles Hunt',
    email: 'charles.hunt@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: true,
    licenses: ['ModelTree: Investment management'],
    lastActivity: new Date('2022-12-19T18:32:04.000Z'),
    createdAt: new Date('2021-12-19T18:00:00.000Z')
  },
  {
    uuid: '2',
    avatarUrl: '',
    fullname: 'Courtney Scott',
    email: 'courtney.scott@2ns.com',
    isBlocked: false,
    isOrganizationAdmin: false,
    licenses: ['Fundy', 'ModelTree: Investment management'],
    lastActivity: new Date('2022-01-22T08:10:12.000Z'),
    createdAt: new Date('2021-01-22T08:10:10.000Z')
  },
  {
    uuid: '3',
    avatarUrl: '',
    fullname: 'Craig Anderson',
    email: 'craig.anderson@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false,
    licenses: ['Fundy'],
    lastActivity: new Date('2021-05-11T08:34:56.000Z'),
    createdAt: new Date('2011-05-11T08:00:00.000Z')
  }
];

const usersMetaInfoMock: ListMetaInfo = {
  totalItems: 50,
  totalPages: 5,
  itemsPerPage: 10,
  currentPage: 2,
  itemCount: 50
};

const usersData: UsersData = {
  items: usersMock,
  meta: usersMetaInfoMock
};

const usersPageDataMock: UsersPageData = {
  statistic: {
    totalUsers: 24,
    activeUsers: 21,
    administrators: 2,
    externalUsers: 1
  }
};

export const filterValues: TableFilterValues = {
  filters: [
    {
      id: 'isAdmin',
      type: 'checkbox',
      groupId: 'userRights',
      label: 'Admin',
      value: false
    },
    {
      id: 'isExternal',
      type: 'checkbox',
      groupId: 'userRights',
      label: 'External user',
      value: false
    }
  ],
  groups: [
    {
      id: 'userRights',
      groupTitle: 'User rights'
    }
  ]
};

export class UsersServiceMock implements UsersService {
  async fetchUsers(
    organizationUuid: string,
    params?: ListParams
  ): Promise<UsersData> {
    try {
      await wait(1000);
      return usersData;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchUsers.name
      );
    }
  }

  async fetchFilters(organizationUuid: string): Promise<TableFilterValues> {
    try {
      await wait(1000);
      return filterValues;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchFilters.name
      );
    }
  }

  async fetchData(): Promise<UsersPageData> {
    try {
      await wait(1000);
      return usersPageDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async deleteUser(organizationUuid: string, userUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteUser.name
      );
    }
  }

  async blockUser(organizationUuid: string, userUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.blockUser.name
      );
    }
  }

  async unblockUser(organizationUuid: string, userUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.unblockUser.name
      );
    }
  }

  async resetPassword(
    organizationUuid: string,
    userUuid: string,
    password: string,
    passwordChangeRequired?: boolean
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.resetPassword.name
      );
    }
  }

  async exportAllUsers(organizationUuid: string): Promise<string> {
    try {
      await wait(1000);
      return '';
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.exportAllUsers.name
      );
    }
  }
}
