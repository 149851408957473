import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { ActivityLogItem, ActivityLogList } from './dataTypes';
import { ListParams } from 'interfaces';
import {
  ActivityLogListSortOptionsDTOFieldNameEnum,
  ActivityLogListSortOptionsDTOOrderEnum,
  ActivityLogPageOrganizationApi,
  ActivityLogPageOrganizationApiActivityLogPageControllerGetActivityLogListRequest
} from '@xq/omni-gateway-frontend-client';

export interface ActivityLogService {
  fetchActivityLogs(
    organizationUuid: string,
    datesInterval?: [Date, Date],
    params?: ListParams
  ): Promise<ActivityLogList>;

  downloadActivityLogs(
    organizationUuid: string,
    datesInterval: [Date, Date],
    searchString?: string
  ): Promise<string>;
}

const omniActivityLogGateway = new ActivityLogPageOrganizationApi(
  omniApiConfiguration
);

export class ActivityLogServiceApi implements ActivityLogService {
  async fetchActivityLogs(
    organizationUuid: string,
    datesInterval?: [Date, Date],
    params?: ListParams
  ): Promise<ActivityLogList> {
    try {
      const requestParams: ActivityLogPageOrganizationApiActivityLogPageControllerGetActivityLogListRequest =
        {
          organizationUuid: organizationUuid,
          searchString: params?.searchValue?.trim(),
          activityLogListOptionsDTORequest: {
            pagination: {
              page: params?.page,
              limit: params?.limit
            },
            sort: [
              {
                order:
                  params?.sortOrder as ActivityLogListSortOptionsDTOOrderEnum,
                fieldName:
                  params?.sortBy as ActivityLogListSortOptionsDTOFieldNameEnum
              }
            ]
          }
        };

      if (
        datesInterval &&
        Array.isArray(datesInterval) &&
        datesInterval.length === 2
      ) {
        requestParams.from = datesInterval[0];
        requestParams.to = datesInterval[1];
      }

      if (params?.searchValue) {
        requestParams.searchString = params?.searchValue?.trim();
      }

      return await omniActivityLogGateway.activityLogPageControllerGetActivityLogList(
        requestParams
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchActivityLogs.name
      );
    }
  }

  async downloadActivityLogs(
    organizationUuid: string,
    datesInterval: [Date, Date],
    searchString?: string
  ): Promise<string> {
    try {
      return await omniActivityLogGateway.activityLogPageControllerGetActivityLogFile(
        {
          from: datesInterval?.[0],
          to: datesInterval?.[1],
          organizationUuid: organizationUuid,
          searchString: searchString?.trim()
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.downloadActivityLogs.name
      );
    }
  }
}

const activityLogsMock: ActivityLogItem[] = [
  {
    userShortInfo: {
      uuid: '1',
      fullname: 'Jayson Randall',
      email: 'jayson.randall@2ns.com',
      isDeleted: false,
      isExternal: true,
      isOrganizationAdmin: false,
      fromAzure: true
    },
    action: {
      name: 'Log in',
      hasWarning: false,
      linkToSeq: 'https://dev.seq.exquance.com/#/events'
    },
    date: new Date(2023, 1, 1, 12, 24, 33),
    application: 'SSO',
    client: {
      name: 'Safari 15.6',
      ip: '192.158.1.38'
    },
    additionalInfo:
      'User is blocked because of several attempts to enter with wrong password'
  },
  {
    userShortInfo: {
      uuid: '2',
      fullname: 'Franklin Stephen Heath',
      email: 'franklin.stephen.heath@2ns.сom',
      isDeleted: false,
      isExternal: true,
      isOrganizationAdmin: false,
      fromAzure: true
    },
    action: {
      name: 'Log out',
      hasWarning: false
    },
    date: new Date(2023, 1, 1, 12, 19, 42),
    application: 'SSO',
    client: {
      name: 'Opera 97',
      ip: '192.53.2.41'
    },
    additionalInfo:
      'User is blocked because of several attempts to enter with wrong password'
  },
  {
    userShortInfo: {
      uuid: '3',
      fullname: 'Shana Brooks',
      email: 'shana.brooks@2ns.com',
      isDeleted: false,
      isExternal: true,
      isOrganizationAdmin: true,
      fromAzure: false
    },
    action: {
      name: 'Failed to login',
      hasWarning: true
    },
    date: new Date(2023, 1, 1, 12, 10, 31),
    application: 'SSO',
    client: {
      name: 'Firefox 112',
      ip: '176.31.109.23'
    },
    additionalInfo:
      'User is blocked because of several attempts to enter with wrong password'
  }
];

const activityLogsListMock: ActivityLogList = {
  items: activityLogsMock,
  meta: {
    totalItems: 50,
    totalPages: 5,
    itemsPerPage: 10,
    currentPage: 1,
    itemCount: 50
  }
};

export class ActivityLogServiceMock implements ActivityLogService {
  async fetchActivityLogs(
    organizationUuid: string,
    datesInterval?: [Date, Date],
    params?: ListParams
  ): Promise<ActivityLogList> {
    try {
      await wait(1000);
      return activityLogsListMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchActivityLogs.name
      );
    }
  }

  async downloadActivityLogs(
    organizationUuid: string,
    datesInterval: [Date, Date],
    searchString?: string
  ): Promise<string> {
    try {
      await wait(1000);
      return '';
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.downloadActivityLogs.name
      );
    }
  }
}
