import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { ListMetaInfo, ListParams } from 'interfaces';
import { LicenseFeaturesItem, LicenseFeaturesList } from './dataTypes';
import {
  LicenseFeatureListPageApi,
  LicenseFeatureListSortOptionsDTOFieldNameEnum,
  LicenseFeatureListSortOptionsDTOOrderEnum
} from '@xq/omni-gateway-frontend-client';

export interface LicenseFeaturesService {
  fetchLicenseFeatures(params: ListParams): Promise<LicenseFeaturesList>;
}

const licenseFeaturesGateway = new LicenseFeatureListPageApi(
  omniApiConfiguration
);

export class LicenseFeaturesServiceApi implements LicenseFeaturesService {
  async fetchLicenseFeatures(params: ListParams): Promise<LicenseFeaturesList> {
    try {
      return await licenseFeaturesGateway.licenseFeatureListPageControllerGetList(
        {
          searchString: params?.searchValue?.trim(),
          licenseFeatureListOptionsDTORequest: {
            pagination: {
              page: params?.page,
              limit: params?.limit
            },
            filters: [],
            sort: [
              {
                order:
                  params?.sortOrder as LicenseFeatureListSortOptionsDTOOrderEnum,
                fieldName:
                  params?.sortBy as LicenseFeatureListSortOptionsDTOFieldNameEnum
              }
            ]
          }
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchLicenseFeatures.name
      );
    }
  }
}

const licenseFeaturesMetaInfoMock: ListMetaInfo = {
  totalItems: 50,
  totalPages: 5,
  itemsPerPage: 10,
  currentPage: 2,
  itemCount: 50
};

const licenseFeaturesItemMock: LicenseFeaturesItem[] = [
  {
    uuid: '1',
    name: 'ERRV 303',
    description:
      'Teams can stay focused on delivering iterative and incremental value',
    createdAt: new Date(2022, 9, 10),
    licenseProduct: 'ModelTree: Valuation'
  },
  {
    uuid: '2',
    name: 'Map Layer',
    description:
      'Bring information from your favorite version control, build, deployment',
    createdAt: new Date(2022, 8, 16),
    licenseProduct: 'Fundy'
  },
  {
    uuid: '3',
    name: 'EWV Approach',
    description:
      'Teams have access to more than a dozen out-of-the-box reports with real-time',
    createdAt: new Date(2022, 8, 10),
    licenseProduct: 'ModelTree: Valuation'
  },
  {
    uuid: '4',
    name: 'Integrations Tab',
    description: 'View repositories with Code',
    createdAt: new Date(2022, 7, 13),
    licenseProduct: 'ModelTree: Investment management'
  },
  {
    uuid: '5',
    name: 'Report Builder',
    description: 'Integrate with developer tools for end-to-end traceability',
    createdAt: new Date(2022, 6, 3),
    licenseProduct: 'ModelTree: Investment management'
  },
  {
    uuid: '6',
    name: 'Reports and insights',
    description:
      'Out-of-the-box reports and dashboards offer critical insights within the context of your work to ensure your teams',
    createdAt: new Date(2022, 5, 1),
    licenseProduct: 'ModelTree: Investment management'
  },
  {
    uuid: '7',
    name: 'Unlimited spaces and pages',
    description: 'Create and organize as much content as your team needs',
    createdAt: new Date(2022, 4, 12),
    licenseProduct: 'ModelTree: Valuation'
  },
  {
    uuid: '8',
    name: 'Page versioning',
    description:
      'Track and view the history of changes to each page, compare versions, and revert changes',
    createdAt: new Date(2022, 3, 28),
    licenseProduct: 'Fundy'
  }
];

const licenseFeaturesMock: LicenseFeaturesList = {
  meta: licenseFeaturesMetaInfoMock,
  items: licenseFeaturesItemMock
};

export class LicenseFeaturesServiceMock implements LicenseFeaturesService {
  async fetchLicenseFeatures(params: ListParams): Promise<LicenseFeaturesList> {
    try {
      await wait(1000);
      return licenseFeaturesMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchLicenseFeatures.name
      );
    }
  }
}
